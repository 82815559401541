import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { isMobile } from 'react-device-detect'
import VizSensor from 'react-visibility-sensor'
import { VolumeUp } from 'styled-icons/typicons'

/**
 * Component for rendering Wistia videos to the browser
 * @param {object} props Component props
 * @param {string} props.videoId Wistia video ID to render
 * @param {object} props.thumbnail  thumbnail for video
 * @param {bool} props.autoplay Autoplay support
 *
 * Useful URLs:
 * https://wistia.com/support/embed-and-share/video-on-your-website
 * https://stackoverflow.com/questions/45358053/how-can-i-use-wistia-inline-player-with-react
 */
export default function EmbedVideo({
  videoId,
  thumbnail = null,
  autoplay = false,
  playlist = false,
  setOnEndedHandler = () => {}
}) {
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(false)
  const [ready, setReady] = useState(false)

  function visibilityHandler(value) {
    if (!playing && value && autoplay) {
      setPlaying(value)
    }
    if (!value) {
      setPlaying(value)
    }
  }

  function onReadyHandler() {
    setReady(true)
  }

  function soundOnHandler() {
    setMuted(false)
    if (!playing) {
      setPlaying(true)
    }
  }

  function onEndedHandler() {
    if (!playlist) {
      setPlaying(false)
      setMuted(true)
    }
    setOnEndedHandler(true)
  }

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: '56.25%'
      }}
    >
      <VizSensor onChange={visibilityHandler}>
        <>
          {isMobile && muted && ready && (
            <div className="absolute top-0 right-0 z-10 w-1/12 rounded-full bg-black shadow-md m-2">
              <VolumeUp
                className="text-white rounded-full"
                onClick={soundOnHandler}
              />
            </div>
          )}

          <ReactPlayer
            style={styles}
            light={(thumbnail && thumbnail[0]?.fluid?.src) ?? false}
            url={`https://goodpath.wistia.com/medias/${videoId}`}
            controls={true}
            playing={playing}
            volume={1}
            muted={muted}
            config={{ wistia: wistiaOptions }}
            width="100%"
            height="100%"
            pip={true}
            onReady={onReadyHandler}
            onEnded={onEndedHandler}
          />
        </>
      </VizSensor>
    </div>
  )
}

const wistiaOptions = {
  volumeControl: true,
  fakeFullScreen: true,
  fitStrategy: 'cover',
  playSuspendedOffScreen: true,
  smallPlayButton: true,
  fullscreenButton: true,
  controlsVisibleOnLoad: true
}
const styles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}
